import { useLocation, Navigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Button,
} from "@mui/material";
import { generateHash } from "utils/functions";

const userId = 'kawamoto-isamu'
// console.log(generateHash(userId))
// hash memo 
// c05bd89fe64ab35ce915aa289311365f6a5eb028e13cf19efbe0cd39b87da5a5

function KawamotoIsamu(): JSX.Element {
  // useParamsの戻り値を適切に型アサーション
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const hash = query.get('hash')

  // 独自のhash値と一致するか検証
  const validHashes = generateHash(userId)// 独自に生成したhash値のリスト
  const isValid = validHashes === hash;

  if (!isValid) {
    // パラメータが無効な場合、エラーページやリダイレクトを表示
    return <Navigate to="/" />;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
      <Card sx={{ maxWidth: 400, boxShadow: 3 }}>
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Avatar
              sx={{ width: 64, height: 64, mr: 2 }}
              src="https://via.placeholder.com/64" // 代用のアバター画像
              alt="User Avatar"
            />
            <Typography variant="h5" component="div">
              川元 勇
            </Typography>
          </Box>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            ID: {userId}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            職業: ソフトウェア開発者
          </Typography>
          <Typography variant="body2" color="text.secondary">
            電話: XXX-XXXX-XXXX
          </Typography>
          <Typography variant="body2" color="text.secondary">
            メール: kawamoto.isamu@example.com
          </Typography>
        </CardContent>
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Button variant="contained" color="primary">
            連絡する
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default KawamotoIsamu;
