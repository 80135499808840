import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Header      from 'components/02_organisms/header/header';
import Footer      from 'components/02_organisms/footer/footer';
import Home        from 'components/03_pages/home/home';
import Contact     from 'components/03_pages/contact/contact';
import Terms from 'components/03_pages/terms/terms';
import Layout from 'components/00_atoms/Layout/layout';
import LegalNotice from 'components/03_pages/legalNotice/legalNotice';
import './App.scss';
import { POP_UP_TARGET_ID } from 'utils/constants';
import KawamotoIsamu from 'components/03_pages/member/KawamotoIsamu';


const App = () => {
  return (
    <div
      className={'app-root'}
    >
      <div id={POP_UP_TARGET_ID}></div>
      <Header/>
      <div className='contents'>
        <Routes>
            <Route path='/'            element={<Home/>}/>
            <Route path="/contact"     element={<Contact />} />
            <Route path="/policy" element={<Layout/>}>
              {/* /policy の場合、ホームへリダイレクト */}
              <Route index element={<Navigate to="/" />} />
              <Route path="terms" element={<Terms />} />
              <Route path="legalNotice" element={<LegalNotice />} />
            </Route>
            <Route path="/member" element={<Layout/>}>
              {/* /policy の場合、ホームへリダイレクト */}
              <Route index element={<Navigate to="/" />} />
              <Route path="kawamoto-isamu" element={<KawamotoIsamu />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />}/>
          </Routes>
      </div>
      <Footer/>
    </div>
  )
}

export default App;
